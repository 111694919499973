// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\Chris\\Documents\\Projects\\chrisparton.net\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-tag-js": () => import("C:\\Users\\Chris\\Documents\\Projects\\chrisparton.net\\src\\templates\\tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-author-js": () => import("C:\\Users\\Chris\\Documents\\Projects\\chrisparton.net\\src\\templates\\author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-post-js": () => import("C:\\Users\\Chris\\Documents\\Projects\\chrisparton.net\\src\\templates\\post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-index-js": () => import("C:\\Users\\Chris\\Documents\\Projects\\chrisparton.net\\src\\templates\\index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\Chris\\Documents\\Projects\\chrisparton.net\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

